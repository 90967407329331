import React from "react";
import LandingPage from "./Screens/LandingPage";

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
