import React from "react";
import { motion, useInView } from "framer-motion";
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

const AboutSection = () => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <div
    style={{
      padding: "40px 20px",
      textAlign: "center",
      maxWidth: "900px",
      margin: "0 auto",
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      marginBottom: "-40px",
    }}
  >
      <div>
        <h2
          style={{
            fontSize: 36,
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          The <span style={{ color: "red", fontStyle: "italic" }}>Buzz</span> about Digitomy:
          <br />
          Revolutionizing Mosquito Surveillance with Innovative Technology
        </h2>
        <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "black",
    }}
  >
          <div
            ref={ref}
            style={{
              flex: "1 1 40%",
              marginBottom: "20px",
              paddingRight: "10px",
            }}
          >
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{ fontSize: "20px", lineHeight: "1.8", marginRight: 40, textAlign: "center" }}
            >
              At Digitomy, our design is geared towards imaging mosquitoes inside of our smart-trap, followed by computer vision algorithms processing the images for classification, based on specific anatomical components.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
