import React from "react";

// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

// Relative Imports - Components
import { InfiniteMovingCards } from "../Components/InfiniteMovingCards";
import abcAction from "../../Assets/RecognitionsAssets/abcAction.png";
import azoRobotics from "../../Assets/RecognitionsAssets/azoRobotics.png";
import bioEngineer from "../../Assets/RecognitionsAssets/bioEngineer.png";
import foxNews from "../../Assets/RecognitionsAssets/foxNews.png";
import spectrumNews from "../../Assets/RecognitionsAssets/spectrumNews.png";

const RecognitionsSection = () => {
    const images = [
      {
        image: foxNews,
        title:
          "USF scientists introduce mosquito tracking dashboard to help keep tabs on insects in Tampa Bay area",
        websiteTitle: "Fox News",
        link: "https://www.fox13news.com/news/usf-scientists-introduce-mosquito-tracking-dashboard-to-help-keep-tabs-on-insects-in-tampa-bay-area",
      },
      {
        image: abcAction,
        title:
          "USF researchers use artificial intelligence to combat malaria in Africa",
        websiteTitle: "ABC Action News",
        link: "https://www.abcactionnews.com/news/local-news/usf-researchers-use-artificial-intelligence-to-combat-malaria-in-africa?",
      },
      {
        image: spectrumNews,
        title:
          "USF scientists say smartphones can aid in mosquito research amid rise in local malaria cases",
        websiteTitle: "Spectrum News",
        link: "https://spectrumlocalnews.com/hi/hawaii/news/2023/07/17/usf-scientists--smartphones-can-aid-in-research-amid-rise-in-malaria-cases",
      },
      {
        image: azoRobotics,
        title: "AI to Help Combat Malaria in Africa",
        websiteTitle: "AZoRobotics",
        link: "https://www.azorobotics.com/News.aspx?newsID=14962",
      },
      {
        image: bioEngineer,
        title:
          "University of South Florida using AI to help combat malaria in Africa",
        websiteTitle: "BioEngineer",
        link: "https://bioengineer.org/university-of-south-florida-using-ai-to-help-combat-malaria-in-africa/",
      },
    ];
  
    return (
      <div className="h-[40rem] rounded-md flex flex-col antialiased bg-white items-center justify-center relative overflow-hidden">
        <h2
          style={{
            fontSize: 36,
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          In the News
        </h2>
        <InfiniteMovingCards items={images} direction="right" speed="slow" />
      </div>
    );
  };

export default RecognitionsSection;